import React, { useState } from 'react';
import '../assets/styles/ContactForm.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setStatus('');
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form validation
    if (!formData.name || !formData.email || !formData.company || !formData.message) {
      setStatus('Please fill out all fields.');
      return;
    }
    if (!validateEmail(formData.email)) {
      setStatus('Please enter a valid email address.');
      return;
    }

    const response = await fetch('https://formspree.io/f/mldeebre', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        company: formData.company,
        message: formData.message
      })
    });

    if (response.ok) {
      setStatus('Thank you for your message!');
      setFormData({
        name: '',
        email: '',
        company: '',
        message: ''
      });
    } else {
      setStatus('Oops! There was a problem submitting your form.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <label>
        Name:
        <input name="name" type="text" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
      </label>
      <label>
        Email:
        <input name="email" type="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
      </label>
      <label>
        Company Name:
        <input name="company" type="text" placeholder="Your Company" value={formData.company} onChange={handleChange} required />
      </label>
      <label>
        Message:
        <textarea name="message" placeholder="Your Message" value={formData.message} onChange={handleChange} required />
      </label>
      <button type="submit">Submit</button>
      {status && <p className="status">{status}</p>}
    </form>
  );
}

export default ContactForm;
