// src/pages/Contact.js

import React from 'react';
import ContactForm from '../components/ContactForm';
import '../assets/styles/Contact.css';

function Contact() {
  return (
    <section className="contact-page">
      <div className="container">
        <h1>Contact Us</h1>
        <p className="section-subtitle">
          We'd love to hear from you! Please fill out the form below and we'll get in touch with you shortly.
        </p>
        <ContactForm />
      </div>
    </section>
  );
}

export default Contact;
