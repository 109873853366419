import React from 'react';
import ServiceCard from '../components/ServiceCard';
import '../assets/styles/Services.css';

function Services() {
  const services = [
    {
      id: 1,
      title: 'Candidate Screening',
      description: 'We meticulously screen candidates to ensure they meet your company\'s standards. Our comprehensive screening process includes background checks, initial interviews, and reference verifications, guaranteeing that only the best talent progresses to the next stage.',
      image: '/images/service1.jpg'
    },
    {
      id: 2,
      title: 'Technical Skill Assessments',
      description: 'Our technical assessments are designed to evaluate the candidates\' expertise in specific areas. We customize assessments to match your technical requirements, ensuring that the developers have the necessary skills to contribute effectively to your projects.',
      image: '/images/service2.jpg'
    },
    {
      id: 3,
      title: 'Hiring Support',
      description: 'From interview scheduling to offer negotiations, we support you throughout the hiring process. Our dedicated team ensures a seamless and efficient recruitment experience, helping you onboard top-tier developers who align with your company\'s vision and culture.',
      image: '/images/service3.jpg'
    }
  ];

  return (
    <section className="services-page container">
      <h1>Our Services</h1>
      <p className="section-subtitle">
        Tailored solutions to meet your specific hiring needs.
      </p>
      <div className="services-list">
        {services.map(service => (
          <ServiceCard
            key={service.id}
            title={service.title}
            description={service.description}
            image={service.image}
          />
        ))}
      </div>
    </section>
  );
}

export default Services;
