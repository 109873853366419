// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container footer-top-container">
          <div className="footer-about">
            <h2>TechnoMuni</h2>
            <p>
              Bridging the gap between innovative software companies and top-tier developers. Let's build something amazing together.
            </p>
            <div className="social-media">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className="footer-links">
            <h3>Quick Links</h3>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
          <div className="footer-contact">
            <h3>Contact Information</h3>
            <ul>
              {/* <li><i className="fas fa-map-marker-alt"></i> 123 Tech Street, Innovation City, TX 75001</li>
              <li><i className="fas fa-phone-alt"></i> (123) 456-7890</li> */}
              <li><i className="fas fa-envelope"></i><a href='mailto:sales@technomuni.com'> sales@technomuni.com</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container footer-bottom-container">
          <p>&copy; {new Date().getFullYear()} TechnoMuni. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
