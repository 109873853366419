import React from 'react';
import Hero from '../components/Hero';
import ServiceCard from '../components/ServiceCard';
import '../assets/styles/Home.css';

function Home() {
  return (
    <div>
      <Hero />
      <section className="services-overview container">
        <h2>Our Services</h2>
        <p className="section-subtitle">
          We offer a comprehensive suite of services to meet your hiring needs.
        </p>
        <div className="services-list">
          <ServiceCard
            iconClass="fa-user-check"
            title="Candidate Screening"
            description="We perform initial candidate screenings to ensure quality."
            image={'/images/service1.jpg'}
          />
          <ServiceCard
            iconClass="fa-laptop-code"
            title="Technical Assessments"
            description="Customized technical assessments to evaluate expertise."
            image={'/images/service2.jpg'}
          />
          <ServiceCard
            iconClass="fa-handshake"
            title="Hiring Support"
            description="We assist throughout the hiring process."
            image={'/images/service3.jpg'}
          />
        </div>
      </section>
    </div>
  );
}

export default Home;
