import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/Hero.css';

function Hero() {
  return (
    <section className="hero">
      <div className="hero-overlay">
        <div className="container hero-container">
          <h1>Connecting Software Companies with Quality Developers</h1>
          <p>We assist software companies in hiring and screening high-quality developers.</p>
          <Link to="/contact" className="btn hero-btn">Get Started</Link>
        </div>
      </div>
    </section>
  );
}

export default Hero;
