import React from 'react';
import '../assets/styles/About.css';

function About() {
  return (
    <section className="about-page">
      <div className="about-overlay">
        <div className="container about-container">
          <h1>About Us</h1>
          <p>
            At TechnoMuni, our mission is to bridge the gap between innovative software companies and top-tier developers.
            We understand the challenges of finding the right talent in today's competitive market, and we're here to streamline
            the hiring process for you.
          </p>
          <p>
            Our team of experts leverages years of industry experience to ensure that every candidate we present meets your
            specific technical and cultural requirements. We are committed to fostering long-term partnerships that drive success
            for both our clients and our developers.
          </p>
          <br></br><br></br>
          <br></br><br></br>
          <br></br><br></br>
          <p>
            Whether you're a startup looking to build your first team or an established enterprise aiming to scale your operations,
            TechnoMuni provides customized solutions tailored to your unique needs.
          </p>
          <br></br><br></br>
          <br></br><br></br>
        </div>
      </div>
    </section>
  );
}

export default About;
